import { SECURITY_AUDIT, SKEY_BOOKLET, SKEY_IMAGES, SKEY_LOGO_PACK, WHITE_PAPER } from "../constants";
import { STBProps } from "../ui/components/molecules/ScrollToBox/ScrollToBox";
import {
  blockchainPath,
  dppPath,
  rwaPath,
  skeyAccessControlPath,
  skeyBoxPath,
  skeyTixPath,
  solutionsPath
} from "./menuLinks";

export const sectionsToScrollTo: STBProps[] = [
  {
    boxTitle: "Overview",
    links: [
      {
        url: "/#overview-partnerships",
        children: "partnerships"
      },
      {
        url: "/#overview-documentation",
        children: "documentation"
      },
      {
        url: "/#overview-products",
        children: "products"
      },
      {
        url: "/#overview-skey-ecosystem",
        children: "node creator"
      },
      {
        url: "/#overview-blockchain",
        children: "skey ecosystem"
      },
      {
        url: "/#skey-network-today",
        children: "skey today"
      }
    ],
    className: ""
  },
  {
    boxTitle: "Solutions",
    links: [
      {
        url: solutionsPath,
        children: "solutions"
      },
      {
        url: dppPath,
        children: "Digital product passport"
      },
      {
        url: rwaPath,
        children: "Real world assets"
      },
      {
        url: skeyBoxPath,
        children: "SkeyBOX"
      },
      {
        url: skeyTixPath,
        children: "SkeyTIX"
      },
      {
        url: skeyAccessControlPath,
        children: "Skey Access Control"
      },
      {
        url: blockchainPath,
        children: "Blockchain"
      },
      {
        url: `${solutionsPath}#deflation-system`,
        children: "Deflation System"
      }
    ],
    className: ""
  },
  {
    boxTitle: "News",
    links: [
      {
        url: "/news#news-articles",
        children: "articles"
      },
      {
        url: "/news#news-story",
        children: "projects story"
      }
    ],
    className: ""
  },
  {
    boxTitle: "Cooperation",
    links: [
      {
        url: "/cooperation#cooperation-cards",
        children: "cooperation"
      }
    ]
  },
  {
    boxTitle: "Development",
    links: [
      {
        url: "/development#development-products",
        children: "development products"
      }
    ],
    className: ""
  },
  {
    boxTitle: "FAQ",
    links: [
      {
        url: "/faq",
        children: "questions"
      },
      {
        url: "/faq#exchange-stocks",
        children: "exchanges"
      }
    ],
    className: ""
  },
  {
    boxTitle: "Downloads",
    links: [
      {
        url: SKEY_LOGO_PACK,
        children: "logo pack",
        download: true,
        target: "_blank"
      },
      {
        url: WHITE_PAPER,
        children: "white paper",
        download: true,
        target: "_blank"
      },
      {
        url: SKEY_IMAGES,
        children: "images",
        download: true,
        target: "_blank"
      },
      {
        url: SECURITY_AUDIT,
        children: "security audit",
        download: true,
        target: "_blank"
      },
      {
        url: SKEY_BOOKLET,
        children: "booklet",
        download: true,
        target: "_blank"
      }
    ],
    className: ""
  }
];
