import { useEffect, useRef } from "react";

export function useTimeout(callback: () => void, delayMs?: number): void {
  const savedCallback = useRef<() => void>();

  // Store the latest callback in the ref.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    // Do nothing if delay is null
    if (!delayMs) return;

    const timeoutId = setTimeout(() => {
      savedCallback.current?.();
    }, delayMs);

    // Clear the timeout on cleanup
    return () => clearTimeout(timeoutId);
  }, [delayMs]);
}
