import React from "react";
import { useWindowDimensions } from "./useWindowDimensions"; // Zakładam, że to jest w oddzielnym pliku

export const useTagSize = () => {
  const [size, setSize] = React.useState({ width: 0, height: 0 });
  const [height, setHeight] = React.useState(0); // Dodaj stan do wysokości
  const ref = React.useRef<any>(null);
  const { viewportHeight, viewportWidth } = useWindowDimensions();

  React.useEffect(() => {
    if (ref.current) {
      setSize({
        width: ref.current.clientWidth,
        height: ref.current.clientHeight
      });
      setHeight(ref.current.clientHeight); // Ustaw początkową wysokość
    }
  }, [viewportHeight, viewportWidth]);

  return { height, width: size.width, setHeight, ref }; // Zwróć setHeight
};
