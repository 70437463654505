import useBoolean from "../../../hooks/useBoolean";
import { useResizeObserver } from "../../../hooks/useResizeObserver";
import { useTagSize } from "../../../hooks/useTagSize";
import { useTimeout } from "../../../hooks/useTimeout2";
import { BaseProps } from "../../../shared/interfaces/BaseProps";
import { classNames } from "../../../utils/classNames";

import "./Expandable.scss";

interface Props extends BaseProps {
  timingMs: number;
  delayMs?: number;
  isExpanded: boolean;
}

export const Expandable = ({ children, timingMs, isExpanded, delayMs = 0, className }: Props) => {
  const timingS = timingMs / 1000;
  // const delay = delayMs / 1000;
  const { height, setHeight, ref } = useTagSize();
  const childrenRef = useResizeObserver(setHeight);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isInitial, yes, no] = useBoolean(true);

  useTimeout(no, timingMs);

  return (
    <div
      className={classNames("expandable", className)}
      style={{
        [`--timing-expandable` as any]: `${isInitial ? 0 : timingS}s`,
        // [`--delayMs` as any]: `${delay}s`,
        maxHeight: isExpanded ? `${height}px` : "0px"
      }}
    >
      <div
        ref={(node) => {
          ref.current = node;
          childrenRef.current = node;
        }}
      >
        {children}
      </div>
    </div>
  );
};
