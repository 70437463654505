import { useEffect, useRef } from "react";

export const useResizeObserver = (onResize: (height: number) => void) => {
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (elementRef.current) {
        onResize(elementRef.current.clientHeight);
      }
    });

    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(elementRef.current!);
      }
    };
  }, [onResize]);

  return elementRef;
};
