import { useState, ReactNode, FC } from "react";

import { BaseProps } from "../../../shared/interfaces/BaseProps";
import { classNames } from "../../../utils/classNames";

import "./Dropdown.scss";
import useClickOutside from "../../../hooks/useClickOutside";

interface DropdownProps extends BaseProps {
  children: ReactNode;
  list: ReactNode;
  direction?: "bottom-left" | "bottom-right" | "top-left" | "top-right";
}

type DropdownStatus = "opened" | "closed" | "init";

const DropdownV2: FC<DropdownProps> = ({ children, list, direction = "bottom-left", ...rest }) => {
  const [status, setStatus] = useState<DropdownStatus>("init");

  const handleToggle = () => {
    setStatus((prev) => (prev === "opened" ? "closed" : "opened"));
  };

  const ref = useClickOutside(() => setStatus((prev) => (prev === "init" ? "init" : "closed")));

  return (
    <div className={classNames("dropdown", rest.className)} style={rest.style} ref={ref as any} onClick={handleToggle}>
      <div className="dropdown-trigger">{children}</div>
      <div className={`dropdown-list ${status} ${direction}`}>{list}</div>
    </div>
  );
};

export default DropdownV2;
