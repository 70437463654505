import { Col, Container, Row } from "react-bootstrap";
import { sectionsToScrollTo } from "../../../data/footerScrollToLinks";
import ScrollToBox from "../../components/molecules/ScrollToBox/ScrollToBox";
import Link from "../../components/atoms/Link/Link";
import { LINKED_IN, PRIVACY_POLICY, SKEY_MAIL, SKEY_SUPPORT, TERMS_AND_CONDITIONS } from "../../../constants";
import { socialMedia } from "../../../data/socialMedia";
import { telegramChannels } from "../../../data/telegramChannels";
import Space from "../../components/atoms/Spacing/Space";
import Image from "../../components/atoms/Image/Image";

import Group from "../../../components/arrangement/Group/Group";
import Stack from "../../../components/arrangement/Stack/Stack";
import { Expandable } from "../../../components/behaviour/Expandable/Expandable";
import useBoolean from "../../../hooks/useBoolean";

import DropdownV2 from "../../components/DropdownV2/DropdownV2";
import { useIsMobile } from "../../../hooks/useIsMobile";

import "./Footer.scss";

const Footer = () => {
  return (
    <>
      <section id="footer">
        <Container fluid="xxl">
          <Container fluid="xl">
            <TopPart />
            <BottomPart />
          </Container>
        </Container>
      </section>

      <section id="pdfs">
        <Container fluid="xl" className="as-row space-between flex-wrap pdfs-holder">
          <Col xs={{ span: 12, order: 2 }} lg={{ span: 6, order: 1 }}>
            <Space space="space-md" className="mobile-lg" />
            <p className="title">© {new Date().getFullYear()} Skey Network. All Rights Reserved.</p>
          </Col>
          <Col xs={{ span: 12, order: 1 }} lg={{ span: 6, order: 2 }} className="terms-and-conditions">
            <Link url={PRIVACY_POLICY} variant="trietary" mode="dark-mode" size="size-extra-small">
              {"Privacy policy"}
            </Link>
            <Link url={TERMS_AND_CONDITIONS} variant="trietary" mode="dark-mode" size="size-extra-small">
              {"Terms and Conditions"}
            </Link>
            <Link url={"/faq"} target="_self" variant="trietary" mode="dark-mode" size="size-extra-small">
              {"FAQ"}
            </Link>
          </Col>
        </Container>
      </section>
    </>
  );
};

export default Footer;

const TopPart = () => {
  return (
    <div className="flex-wrap">
      <Col xs={12} xl={6} className="as-row mb-lg flex-wrap">
        <Linkedin />
      </Col>
      <Col xs={12} className="as-col">
        <Media />
        <Space space="space-xl" />
      </Col>
    </div>
  );
};

const BottomPart = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isExpanded, expand, collapse, toggle] = useBoolean(false);
  return (
    <Stack fullWidth alignItems="center">
      <button
        onClick={toggle}
        className="w-xs-100"
        style={{ maxWidth: "330px", height: "48px", border: "1px solid white" }}
      >
        <Group fullSize colGap={8} justifyContent="center" alignItems="center">
          <p className="p2 white-0">{isExpanded ? "Show less" : "Show more"}</p>
          <img
            src="/images/icons/chevron-down-white.svg"
            alt="chevron"
            style={{ rotate: isExpanded ? "180deg" : "0deg" }}
          />
        </Group>
      </button>
      <Expandable timingMs={300} isExpanded={isExpanded}>
        <Space space="space-xxl" />
        <MiddlePart />
        <Space space="space-xxl" />
        <ProjectInfo />
      </Expandable>
    </Stack>
  );
};

const MiddlePart = () => {
  const isMobile = useIsMobile("md");
  return (
    <Group rowGap={24} alignItems="flex-start" className="wrap">
      {sectionsToScrollTo.map((link, id) => {
        return (
          <ScrollToBox
            boxTitle={link.boxTitle}
            style={{ maxWidth: isMobile ? "50%" : "25.1%" }}
            className="w-xs-100"
            links={link.links}
            key={id}
          />
        );
      })}
    </Group>
  );
};

const ProjectInfo = () => {
  return (
    <Row>
      <Col xs={6} lg={3} className="children-as-white">
        <Disclaimer />
      </Col>
      <Col xs={6} lg={3} className="children-as-white">
        <Contact />
      </Col>
      <Col xs={12} lg={6} className="children-as-white mt-xs-xxl">
        <ProjectReporting />
        <Space space="space-lg" />
      </Col>
    </Row>
  );
};

const Linkedin = () => {
  return (
    <>
      <p className="s2 title mr-xs">Check out our team on</p>
      <Link
        url={LINKED_IN}
        icon="/images/linkedin.svg"
        iconHovers={false}
        className="linked-in"
        variant="trietary"
        mode="dark-mode"
        size="size-small"
      >
        LinkedIn account
      </Link>
    </>
  );
};

const Media = () => {
  return (
    <>
      <p className="s2 title mb-sm">Follow Us</p>
      <div className="as-row flex-wrap with-spacing social-media">
        {socialMedia.map((socialMediaItem) => {
          return (
            <a href={socialMediaItem.url} target="blank" rel="norefferer noopener" key={Math.random()}>
              <img width="31" height="30" src={socialMediaItem.logoPath} alt="social media button" />
            </a>
          );
        })}
        <TelegramTooltip />
      </div>
    </>
  );
};

const Disclaimer = () => {
  return (
    <>
      <p className="s2">Legal disclaimer</p>
      <Space space="space-md" />
      <p>Project implemented by</p>
      <p>the company:</p>
      <p>BoTGlobal OÜ</p>
      <p>Maakri tn 19/1-7K Tallinn</p>
      <p>10145 Estonia</p>
    </>
  );
};

const Contact = () => {
  return (
    <>
      <p className="s2">Contact</p>
      <Space space="space-md" />
      <p>Information</p>
      <Link url={`mailto:${SKEY_MAIL}`} variant="trietary" mode="dark-mode" size="size-extra-small">
        {SKEY_MAIL}
      </Link>
      <Space space="space-sm" />
      <p>Integration and tests of</p>
      <p>the Skey Network</p>
      <p>platform</p>
      <Link url={`mailto:${SKEY_SUPPORT}`} variant="trietary" mode="dark-mode" size="size-extra-small">
        {SKEY_SUPPORT}
      </Link>
    </>
  );
};

const ProjectReporting = () => {
  return (
    <>
      <Space space="space-xxl" className="mobile-lg" />
      <p className="s2">Project reporting</p>
      <Space space="space-md" />
      <Image imgWidth={54} imgHeight={36} imgPath="/images/europe-flag.svg" alt="europe flag" />
      <Space space="space-sm" />
      <p>
        The company operates in accordance with the European Union law regarding the principles of accounting and
        reporting. Financial data and risk reporting by a certified auditing company, CIA audit standard (Certified
        Internal Auditor).
      </p>
    </>
  );
};

const TelegramTooltip = () => {
  return (
    // <WithTooltip
    //   alwaysShown={<img width="27" height="30" src="/images/telegram-hover.svg" alt="telegram" />}
    //   externalClassForTooltip={"right tooltip-styling"}
    //   tooltipInside={
    //     <>
    //       <p className="s2 mb-sm tooltip-title">Telegram</p>
    //       <TelegramLinksList />
    //     </>
    //   }
    // />
    <DropdownV2
      className="pointer"
      direction="top-left"
      list={
        <Stack fullWidth className="tooltip-styling">
          <p className="s2 mb-sm tooltip-title">Telegram</p>
          <TelegramLinksList />
        </Stack>
      }
    >
      <img width="27" height="30" src="/images/telegram-hover.svg" alt="telegram" />
    </DropdownV2>
  );
};

const TelegramLinksList = () => {
  return (
    <>
      {telegramChannels.map((channel) => (
        <Link url={channel.url} className={"social-media-link"} key={Math.random()} variant="trietary" mode="dark-mode">
          {channel.label}
        </Link>
      ))}
    </>
  );
};
